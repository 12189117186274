/* website: 2920-repentignychevrolet2
 * created at 2019-12-31 2:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/gmc-preferred.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.hide-credit-fees{
    .tile-payment-detail{
      &.small{
        display: none;
      }
    }
  }
}